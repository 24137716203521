import React from 'react';
import FA from 'react-fontawesome';
import classNames from 'classnames';
import style from './teammember.module.scss';
//@ts-ignore
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import {
  getBusinessCard,
  getTeamMemberId
} from '../../utils/businesscard-utils';

export interface TeamMemberProps {
  image: string;
  name: string;
  firstname: string;
  position: string;
  mail: string;
  mobile?: string;
  phone?: string;
  fax: string;
  description: string;
  specialisation: string[];
  timeline: TimelineItem[];
  hideVcf?: boolean;
}

interface TimelineItem {
  from: string;
  to: string;
  job: string;
}

const TeamMember: React.FC<TeamMemberProps> = (props: TeamMemberProps) => {
  return (
    <div className={classNames(style.member)}>
      {/*<h1
        className={classNames(
          style.name
        )}>{`${props.firstname} ${props.name}`}</h1>*/}
      <div className={classNames('row', style.linkcontainer)}>
        <div className='col-md-3 col-sm-4 col-xs-12'>
          <FA className={classNames(style.icon)} name='phone' />
          <a href={`tel: ${props.phone}`} className={classNames(style.text)}>
            Tel: {props.phone}
          </a>
          <br />
          {props.mobile && (
            <>
              <FA className={classNames(style.icon)} name='mobile' />
              <a
                href={`tel: ${props.mobile}`}
                className={classNames(style.text)}>
                Mobil: {props.mobile}
              </a>
            </>
          )}
        </div>
        <div className='col-md-6 col-sm-8 col-xs-12'>
          {props.fax && (
            <>
              <FA className={classNames(style.icon)} name='fax' />
              <a href={`tel: ${props.fax}`} className={classNames(style.text)}>
                Fax: {props.fax}
              </a>
            </>
          )}
          <br />
          <FA className={classNames(style.icon)} name='envelope' />
          <a href={`mailto: ${props.mail}`} className={classNames(style.text)}>
            e-Mail: {props.mail}
          </a>
        </div>
        <div className='col-md-3 col-xs-12'>
          {props.hideVcf ? (
            <></>
          ) : (
            <a
              href={getBusinessCard(getTeamMemberId(props))}
              className={classNames(style.downloadCard)}>
              Visitenkarte herunterladen
            </a>
          )}
        </div>
      </div>
      <div className={classNames('row around-xs', style.timeline)}>
        <Timeline lineColor={'#ddd'}>
          {props.timeline.map((item: TimelineItem, index: number) => (
            <TimelineItem
              key={`timeline-${index}`}
              dateText={`${item.from} - ${item.to}`}
              style={{ color: '#008000' }}>
              {item.job}
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default TeamMember;
